import * as React from "react";

function NotFoundPage() {
  return (
    <main>
      <title>Not found</title>
      <h1>Page not found</h1>
    </main>
  );
}

export default NotFoundPage;
